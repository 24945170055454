.mr-auto {
    margin: 0 auto;
}
.Sucess-bg {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 20px;
    padding: 33px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.sucess {
    width: 90px;
    margin-bottom: 20px;
}
.sucess-title {
    font-size: 30px;
    line-height: normal;
    margin-bottom: 17px;
    text-align: center;
}
.sucess-sbtxt {
    font-size: 16px;
    line-height: normal;
    color: #000;
    text-align: center;
    margin-bottom: 35px;
}

@media screen and (max-width : 480px) {
    .Sucess-bg {
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
    }
}