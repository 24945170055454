.administration-banner {
    max-width: 1800px;
    background-image: url('/public/assets/imgs/department/aboutus-banner.png');
    background-repeat: no-repeat;
    background-position: center;
    padding: 150px 0px;
    margin: 0 auto;
    background-size: cover;
    position: relative;
    overflow: hidden;
}
.department-sbtxt {
    font-size: 18px;
    color: #444;
    line-height: 27px;
    margin-bottom: 0;
    text-align: left;
    /* padding-right: 15px; */
}
.department-img {
    width: 93%;
    border-radius: 10px;
    height: 380px;
    object-fit: cover;
    /* box-shadow: 0 4px 15px 0 rgba(75, 75, 75, 0.37); */
}
.img-flex {
    display: flex;
    justify-content: end;
}
.img-item {
    display: flex!important;
    justify-content: end;
}
.investor-title h2 {
    font-size: 30px;
    margin-bottom: 0;
}
@media screen and (max-width: 768px) {
    .department-img {
        width: 100%;
    }
}