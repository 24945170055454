.login-bg .slider-login {
    background: url(/public/assets/imgs/login_registerbg.png);
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 700px;
}
.fslogin-bg {
    height: auto;
    background-repeat: no-repeat !important;
}
.login-content {
    background-color: rgba(0, 0, 0, 0.4);
    height: inherit;
}
.mg-tp {
    /* display: inline-block; */
    top: 120px;
    margin: 0 auto;
}
.login-form-row {
    position: relative;
    padding: 30px 30px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.07);
}
.txt-center {
    font-size: 40px;
    line-height: normal;
    text-align: center;
    color: #000;
    margin-bottom: 35px;
}
.bg-pill {
    display: flex;
    background: #FFF1EB;
    border-radius: 30px;
    margin-bottom: 35px;
}
.nav-pills .nav-link {
    font-size: 18px;
    color: #333333;
    padding: 10px 25px;
}
.nav-pills .nav-link.active {
    background: linear-gradient(90deg, #FB5220 30%, #f1805e 80.77%);
    color: #fff;
    font-weight: 600;
    border-radius: 30px;
}
.input-grp {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 24px;
    color: #000;
}
.input-grp img {
    width: 20px;
}
.input-grp input {
    border: 0;
    background-color: transparent!important;
    /* box-shadow: none!important; */
    line-height: initial;
    height: 60px;
    font-size: 16px;
}
.input-grp input:focus {
    box-shadow: none;
}
input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0px 9999px white;
}
input:-webkit-autofill:focus {
    -webkit-box-shadow:0 0 0 50px white inset;
}
.form-check-input:checked {
    background-color: #FB5220;
    border-color: #FB5220;
}
.register-link {
    text-align: center;
    font-size: 14px;
    margin-bottom: 0;
}
label a {
    color: #FB5220;
    text-decoration: none;
}
.register-link a{
    color: #FB5220;
    text-decoration: none;
}

@media screen and (max-width:480px) {
    .login-form-row {
        padding-left: 10px;
        padding-right: 10px;
    }
}