.Farmer-banner{
    background: url('/public/assets/imgs/farmer-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 477px;
    text-align:start;
    display: flex;
    justify-content: center;
    align-items: center;
}

.farmer-name {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
}

.farmer-name h2 span {
    color: #FB5220;
}
.green{
    color: #5D9712!important;
}