.manufacturing-banner {
    max-width: 1800px;
    background-image: url('/public/assets/imgs/department/aboutus-banner.png');
    background-repeat: no-repeat;
    background-position: center;
    padding: 150px 0px;
    margin: 0 auto;
    background-size: cover;
    position: relative;
    overflow: hidden;
}
.pd-lft {
    padding-left: 0px;
    padding-right: 0;
}
.img-flex-manu {
    display: flex;
    justify-content: start;
}