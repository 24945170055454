
@font-face {
  font-family: 'Regular-font';
  /* src: url("../public/assets/font/noto-sans/NotoSans-Regular.ttf");
  src: local('☺'),url("../public/assets/font/noto-sans/NotoSans-Regular.ttf") format('truetype'); */
  
  src: url("../public/assets/font/mukta/Mukta-Regular.ttf");
  src: local('☺'),url("../public/assets/font/mukta/Mukta-Regular.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Medium-font';
  /* src: url("../public/assets/font/noto-sans/NotoSans-Medium.ttf");
  src: local('☺'),url("../public/assets/font/noto-sans/NotoSans-Medium.ttf") format('truetype'); */

  src: url("../public/assets/font/mukta/Mukta-Medium.ttf");
  src: local('☺'),url("../public/assets/font/mukta/Mukta-Medium.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Semibold-bold';
  /* src: url("../public/assets/font/noto-sans/NotoSans-SemiBold.ttf");
  src: local('☺'),url("../public/assets/font/noto-sans/NotoSans-SemiBold.ttf") format('truetype'); */

  src: url("../public/assets/font/mukta/Mukta-SemiBold.ttf");
  src: local('☺'),url("../public/assets/font/mukta/Mukta-SemiBold.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Bold-font';
  /* src: url("../public/assets/font/noto-sans/NotoSans-Bold.ttf");
  src: local('☺'),url("../public/assets/font/noto-sans/NotoSans-Bold.ttf") format('truetype'); */

  src: url("../public/assets/font/mukta/Mukta-Bold.ttf");
  src: local('☺'),url("../public/assets/font/mukta/Mukta-Bold.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Regular-font', sans-serif !important;
}
.font-regular{
  font-family: 'Regular-font', sans-serif !important;
}
.font-medium{
  font-family: 'Medium-font', sans-serif !important;
}
.font-semibold{
  font-family: 'Semibold-bold', sans-serif !important;
}
.font-bold{
  font-family: 'Bold-font', sans-serif !important;
}

/* navbar css */
.navbar-toggler:focus {
  box-shadow: none;
}
.main-navbar {
  z-index: 99!important;
}
.desk-switch-display-none{
  display: none!important;
}
.lang-mobswitch {
  display: none;
}
.kranti-logo {
  width: 80px;
}
.mobile-kranti-logo {
  display: none;
}
.scroll-header-bg .main-navbar{
  background-color: #fff!important;
  border-bottom: 2px solid #ddd;
  transition: all 0.5s;
}
.scroll-header-bg .main-navbar ul.navbar-nav li.nav-item .nav-link {
  color: #000;
  transition: all 0.5s;
}
.main-navbar ul.navbar-nav li.nav-item .nav-link {
  font-size: 16px;
  padding: 10px 0px;
  margin: 0 11px;
  font-family: 'Regular-font', sans-serif !important;
  color: #fff;
}
.main-navbar ul.navbar-nav li.nav-item .nav-link:hover {
  font-weight: 700;
  color:#FB5220 !important;
  transition: all 0.3s;
}
.main-navbar .navbar-nav .nav-link.active, .navbar-nav .show>.nav-link{
  font-weight: 700;
  color:#FB5220 !important;
  border-bottom: 3px solid #FB5220;
}
.dropdown-menu {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
  transition: .3s;
  opacity:1;
  visibility:visible;
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.dropdown:hover > .dropdown-menu {
  display: block;
  margin-top: 0;
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}
.inner-link {
  display: flex;
  align-items: center;
  /* padding-right: 20px; */
}
.dropdown-item {
  font-size: 16px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.dropdown-item:hover {
  background-color: #FFF1EB!important;
  color: #FB5220;
}
.dropdown-item.active {
  background-color: #fb5220;
}
.inner-link:hover {
  background-color: #FFF1EB!important;
  color: #FB5220;
}
@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0rem);
    opacity: 1;
  }

  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }
}
@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }

  0% {
    -webkit-transform: translateY(-1rem);
    -webkit-opacity: 0;
  }
}
.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}
.lang-switch {
    display: flex;
    background: #D9D9D9;
    border-radius: 30px;
    overflow: hidden;
    padding :0;
    margin: 0;
}
.desk-view {
  display: none;
}
.input[type=radio] {
  box-sizing: border-box;
  padding: 0;
  height: 14px;
  width: 24px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #D9D9D9;
  border-radius: 2px;
  position: relative;
}
.lang-switch input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}
.lang-switch label:hover {
  cursor: pointer;
}
.lang-switch label {
  background-color: #D9D9D9;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  padding: 10px 25px;
  margin: 0;
  text-transform: uppercase;
  /* margin-right: -1px; */
  transition: all 0.1s ease-in-out;
}
::-webkit-scrollbar {
  width: 10px;
  background-color: #ddd;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  background: #FB5220;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #721c02;
}


/* footer-css */
.unique-color-dark {
  background-color: #000;
}
.pd-60 {
  padding-top: 40px;
}
.subscribe-quote {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0 ;
  border-bottom: 1px solid #454647;
}
.subscribe-letter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footer-quote {
  font-size: 32px;
  line-height: 50px;
  color: #fff;
  width: fit-content;
  text-align: center;
  margin-bottom: 0;
}
.subscribe-letter p{
  font-size: 15px;
  line-height: 30px;
  margin-bottom: 12px;
  color: #fff;
}
.subscribe-input {
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-sub {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding: 13px 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px 0px 0px 5px;
  color: #000;
  width: 300px;
}
.input-sub input {
  border: none;
  background-color: transparent;
  line-height: initial;
  height: initial;
  font-size: 16px;
  padding: 0;
}
.sub-btn {
  border-radius: 0px 1px 0px 1px;
}
.footer-left-section {
  text-align: initial;
}
.footer-left-section .footer-logo {
  max-width: 160px;
  margin-bottom: 30px;
}
.footer-left-section p {
  color: #fff;
}
.link-header{
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 20px;
  color: #fff;
}

.footer-left-section a {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
}
.footer-links {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 18px;
}
.location-link {
  display: flex;
  align-items: flex-start;
}
.location-link p {
  margin-top: -3px;
}
.footer-links p {
  margin-bottom: 0;
  font-size: 16px;
}
.footer-arrow {
  margin-right: 9px;
  width: 11px;
  filter: brightness(0) invert(1);
}
.c-info {
  width: 14px;
  margin-right: 10px;
}
.footer-social-links {
  display: flex;
  align-items: center;
  justify-content: start;
}
.footer-icon {
  height: 25px;
  margin-right: 15px;
}
.footer-copyright-bg {
  border-top: 1px solid #454647;
  padding: 16px 0;
  color: #fff;
}
.footer-copyright-bg p {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 16px;
}
@media (min-width: 768px) {

  .animate {
      animation-duration: 0.3s;
      -webkit-animation-duration: 0.3s;
      animation-fill-mode: both;
      -webkit-animation-fill-mode: both;
      }
  }

  @media screen and (max-width : 990px) {
    .mobile-kranti-logo {
      display: block;
      width: 63px!important;
    }
    .kranti-logo {
      display: none;
    }
  }

@media screen and (max-width : 768px) {
  .kranti-logo {
    width: 63px!important;
  }
  .mobile-logo-pd {
    padding: 10px 0;
  }
  .main-navbar {
    background-color: #fff;
    top: 0px;
    padding: 0px;
  }
  .desk-view {
    display: none;
  }
  .mobile-switch-display-none {
    display: none!important;
  }
  .lang-mobswitch {
    position: fixed;
    background-color: #fff;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
  }
  .cstm-collapse {
    background-color: #fff;
  }
  .cstm-collapse ul.navbar-nav li.nav-item .nav-link {
    font-size: 15px;
    color: #000!important;
   }
   .subscribe-quote {
    display: flex;
    flex-direction: column;
    align-items: center;
   }
   .subscribe-letter p {
    margin-bottom: 20px;
   }
   .subscribe-input {
    display: flex;
    flex-direction: column;
    width: 100%;
   }
   .input-sub {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 0;
   }
   .sub-btn {
    width: 100%;
    border-radius: 0;
   }
}