.esl-banner {
    max-width: 1800px;
    background-image: url('/public/assets/imgs/department/aboutus-banner.png');
    background-repeat: no-repeat;
    background-position: center;
    padding: 150px 0px;
    margin: 0 auto;
    background-size: cover;
    position: relative;
    overflow: hidden;
}
.broucher-list {
    margin-top: 50px;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    -moz-column-gap: 25px;
    column-gap: 25px;
    row-gap: 25px;
}
.broucher-list li {
    background: #fff;
    border-radius: 10px;
    color: #FB5220;
    font-size: 16px;
    overflow: hidden;
    border: 1px solid #FB5220;
    transition: all 0.5s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.25rem;
}
.broucher-list li:hover {
    background: #FB5220;
    color: #fff;
    transition: all 0.5s;
    transform: translateY(-10px);
}
.broucher-list li:hover >a {
    color: #fff;
}
.broucher-list li a {
    color: #000;
    transition: all 0.5s;
    /* height: 100%; */
    text-decoration: none;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 330px;
}
.broucher-icon {
    width: 40px;
}

@media screen and (max-width: 767px) {
    .broucher-list {
        grid-template-columns: 1fr;
    }
}