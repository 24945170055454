.gallery-banner {
    max-width: 1800px;
    background-image: url('/public/assets/imgs/gallery/kranti-gallerybg.png');
    background-repeat: no-repeat;
    background-position: center;
    padding: 150px 0px;
    margin: 0 auto;
    background-size: cover;
    position: relative;
    overflow: hidden;
}
/* .gallery-tabs {
    background-color: #fff;
    padding-top: 40px;
} */
.gallery-img {
    height: 380px;
    object-fit: cover;
    width: -webkit-fill-available;
}
.leadership-modal-img img {
    border-radius: 10px;
}
.yt-main-video {
    border-radius: 15px;
    border: 1px solid #FB5220;
}