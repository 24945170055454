.Aboutus-banner {
    background: url('/public/assets/imgs/aboutus-img/aboutus-banner.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 477px;
    text-align:start;
    display: flex;
    justify-content: center;
    align-items: center;
}
.abt-pdtop {
    padding-top: 100px;
}
.leadership-bg {
    position: relative;
    background: url('/public/assets/imgs/aboutus-img/leadership.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    border-right: 1px solid #fff;
    opacity: 0.6;
    transition: all 0.3s ease-in-out;
}
.leadership-bg::after {
    content: "";
    display: block;
    position: absolute;
    /* bottom: 0; */
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 66.03%, #000 100%);
    /* border-radius: 24px; */
    pointer-events: none;
}
.leadership-bg:hover {
    transition: all 0.3s ease-in-out;
    opacity: 1;
}

.leadersec-title {
    font-size: 30px;
    color: #fff;
    line-height: 50px;
    margin-bottom: 0;
}
.leadersec-sbtitle {
    font-size: 18px;
    color: #fff;
    line-height: normal;
}
.sec-lm-btn {
    background-color: transparent;
    color: #fff;
    padding:0 0 10px 0;
    border-bottom: 1px solid #fff;
    border-radius: 0;
}
.sec-lm-btn:hover {
    background-color: transparent;
    color: #fff;
    border-bottom: 1px solid #fff;
}
.Arrow-white {
    margin-left: 12px;
    filter: brightness(0) invert(1);
}
.investors-bg {
    position: relative;
    background: url('/public/assets/imgs/aboutus-img/investors.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    border-right: 1px solid #fff;
    opacity: 0.6;
    transition: all 0.3s ease-in-out;
}
.investors-bg::after {
    content: "";
    display: block;
    position: absolute;
    /* bottom: 0; */
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 66.03%, #000 100%);
    /* border-radius: 24px; */
    pointer-events: none;
}
.investors-bg:hover {
    transition: all 0.3s ease-in-out;
    opacity: 1;
}
.krantigallery-bg {
    position: relative;
    background: url('/public/assets/imgs/aboutus-img/kranti-gallary.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    opacity: 0.6;
    transition: all 0.3s ease-in-out;
}
.krantigallery-bg::after {
    content: "";
    display: block;
    position: absolute;
    /* bottom: 0; */
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 66.03%, #000 100%);
    /* border-radius: 24px; */
    pointer-events: none;
}
.krantigallery-bg:hover {
    transition: all 0.3s ease-in-out;
    opacity: 1;
}
.abt-para-mrg {
    margin-top: 20px;
    margin-bottom: 50px;
}
.sb-heading {
    font-size: 20px;
    line-height: normal;
    margin-bottom: 10px;
    color: #989898;
}
.bold-reg {
    display: flex;
    align-items: baseline;
    gap: 10px;
}
.bold-we {
    font-size: 40px;
    color: #FB5220;
    margin-bottom: 0;
    font-weight: 600;
}
.regular-txt {
    font-size: 40px;
    color: #333;
    margin-bottom: 0;
    line-height: 55px;
}
.vm-brief {
    font-size: 20px;
    line-height: normal;
    margin-top: 25px;
    color: #666;
    margin-bottom: 0;
    padding-right: 25px;
}
.card-bg {
    padding: 40px 35px;
    background-color: #fff;
    border-radius: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    border: 1px solid #ddd;
}
.four-cardimg {
    width: 87px;
    height: auto;
}
.four-card-title {
    line-height: normal;
    font-size: 20px;
    color: #000;
    margin-bottom: 0;
}
.four-card-sbtxt {
    font-size: 14px;
    margin-bottom: 0;
    line-height: 26px;
    height: 78px;
    color: #000;
    text-align: center;
}
.bg-white {
    background-color: #fff;
}
.season-selectn-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.season-selectn {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.select-title {
    margin-bottom: 0;
    color: #fff;
    margin-right: 10px;
}

.awards-list {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
}
.awards-list img {
    border-radius: 10px;
}
.awards-list:hover .awards-details{
    bottom: auto;
    top: 0;
    z-index: 9;
}
.awards-details {
    background: #FB5220;
    border-radius: 15px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 12px;
    display: flex;
    visibility: visible;
    opacity: 1;
    transition: all linear 0.3s;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: end;
}
.awards-details h5 {
    font-size: 24px;
    color: #fff;
}
.awards-details p {
    font-size: 16px;
    color: #fff;
    line-height: 25px;
    margin-bottom: 0;
}
.awards-mobile {
    display: none;
}
@media screen and (max-width : 768px) {
    .leadership-bg, .investors-bg {
        border-bottom: 1px solid #fff;
        border-right: 0;
    }
    .awards-desktop {
        display: none;
    }
    .awards-mobile {
        display: block;
        margin-top: 30px;
    }
    .vm-brief {
        margin-bottom: 40px;
    }
    .card-bg {
        margin-bottom: 20px;
    }
    .abt-cmpn-over-wrp {
        height: 580px!important;
    }
}
@media screen and (max-width : 480px) {
    .bold-reg {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 0;
    }
    .bold-we {
        font-size: 70px;
        color: #FB5220;
        margin-bottom: 0;
        line-height: 80px;
    }
    .regular-txt {
        font-size: 50px;
        color: #333;
        margin-bottom: 0;
    }
    .season-selectn-heading {
        display: flex;
        flex-direction: column;
    }
}
@media (max-width: 480px) {

}