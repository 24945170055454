.tenderapply-bg {
    z-index: 9;
    background-image: url('/public/assets/imgs/department/departments-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 130px 45px;
}
.tenderlist-bg {
    background-color: #fff;
    padding: 30px 25px;
    border-radius: 30px;
    border: 1px solid #ddd;
}
.othertender-listing {
    height: 500px;
    overflow: hidden;
    overflow-y: scroll;
}
.othertenders {
    font-size: 24px;
    line-height: normal;    
    margin-bottom: 5px;
}
.list-block {
    display: flex;
    /* align-items: center; */
    justify-content: flex-start;
    border-bottom: 1px solid #DDD;
    padding-bottom: 20px;
    margin-top: 20px;
}
.date-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
}
.date-block p {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 0;
}
.tender-namedesc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.tender-namedesc .tendername{
    font-size: 16px;
    line-height: normal;
    width: 200px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 5px;
}
.tender-namedesc .tenderdesc {
    font-size: 14px;
    line-height: normal;
    width: 200px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #454647;
    margin-bottom: 10px;
}
.applyform-bg {
    background-color: #fff;
    padding: 30px 25px;
    border-radius: 30px;
    border: 1px solid #ddd;
}
.applynow-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 50px;
}
.apply-title {
    font-size: 40px;
    line-height: 55px;
    margin-bottom: 0;
    margin-left: 15px;
}
.back {
    width: 40px;
}
.date {
    display: flex;
    align-items: start;
    justify-content: flex-start;
    margin-bottom: 30px;
}
.date-title {
    font-size: 18px;
    line-height: normal;
    margin-right: 5px;
    margin-bottom: 0;
}
.main-date {
    color: #454647;
    font-size: 18px;
    line-height: normal;
    margin-bottom: 0;
}
.document {
    display: flex;
    align-items: center;
}
.download-doc {
    background-color: #fb532011;
    border: 1px solid #FB5220;
    color: #FB5220;
    font-size: 16px;
    font-weight: 500;
    padding: 8px;
}
.upload-docs {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed #ddd;
    max-height: 170px;
    cursor: pointer;
    padding: 25px 0;
    border-radius: 10px;
}
.file {
    width: 60px;
}
.uploaded-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #c6c6c643;
    border-radius: 10px;
    margin-top: 20px;
}
.uploadeddoc-name {
    display: flex;
    align-items: center;
    gap: 10px;
}
.uploadeddoc-name span {
    font-size: 16px;
    line-height: normal;
}
.doc-icon {
    width: 20px;
}
.removedoc-icon{
    width: 20px;
}
.cancel-submit {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.tender-cancel {
    background-color: #fff;
    border: 1px solid #FB5220;
    color: #FB5220;
    padding: 10px 40px;
}
.tender-cancel:hover {
    background-color: #e4e4e4;
    color: #000;
}
.tender-submit {
    background-color: #FB5220;
    color: #fff;
    padding: 10px 40px;
}
.tender-submit:hover {
    background-color: #f78866;
}

@media screen and (max-width : 480px) {
    .othertender-listing {
        height: 230px;
        overflow: hidden;
        overflow-y: scroll;
    }
    .tenderapply-bg {
        padding-left: 5px;
        padding-right: 5px;
    }
    .mbl-row {
        margin-top: 20px;
    }
    .applyform-bg {
        padding-left: 5px;
        padding-right: 5px;
    }
    .apply-title{
        font-size: 24px;
        margin-left: 10px;
    }
    .date {
        display: flex;
        flex-direction: column;
    } 
    .document {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .uploaded-row {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .uploadeddoc-title {
        width: 120px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .cancel-submit {
        display: flex;
        justify-content: center;    
    }
}