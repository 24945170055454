.canedeve-banner {
    background: url('/public/assets/imgs/aboutus-img/aboutus-banner.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 477px;
    text-align:start;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cane-deve-bg {
    background-color: #f6f6f6;
}
.candeve-content-bg {
    background-color: #fff;
    padding: 30px 0;
}
.deve-facility {
    margin-bottom: 40px;
    padding: 0 8px;
}
.cane-development-title {
    font-size: 30px;
    margin-bottom: 40px;
    font-weight: 600;
    border-left: 3px solid #FB5220;
    text-align: left;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 15px;
    line-height: 36px;
}
.cane-facility-img {
    margin-bottom: 20px;
}
.cane-facility-img img {
    border-radius: 10px;
    width: 100%;
    height: 250px;
    object-fit: cover;
}
.facility-name {
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    color: #333;
    margin-bottom: 12px;
    font-family: 'Semibold-bold', sans-serif
}
.facility-desc {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0;
    text-align: left;
}

@media screen and (max-width:480px ) {
    .cane-facility-img img {
        height: 220px;
    }
}