.contact-banner {
    background: url('/public/assets/imgs/contact-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 477px;
    text-align:start;
    display: flex;
    justify-content: center;
    align-items: center;
}
.git {
    z-index: 9;
    background-color: #fff;
    padding-top: 100px;
    padding-bottom: 80px;
}
.cnt-pd-lft {
    padding-left: 30px;
}
.contact-heading h2{
    color: #333;
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    border-left: 3px solid #FB5220;
    text-align: left;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 15px;
    margin-bottom: 40px;
}
.address-heading h2 {
    color: #333;
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    border-left: 3px solid #FB5220;
    text-align: left;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 15px;
    margin-bottom: 40px;
}
.page-subheading {
    font-size: 18px;
    color: #454545;
    margin: 0;
}
.contact-info {
    display: flex;
    align-items: center;
    padding: 12px 0;
    justify-content: flex-start;
}
.contact-info-address {
    display: flex;
    align-items: flex-start;
    padding: 12px 0;
    justify-content: flex-start;
}
.info-icon {
    width: 20px;
    height: 20px;
    object-fit: contain;
}
.info {
    margin-left: 15px;
}
.info h6 {
    font-weight:600;
}
.info p{
    margin-bottom: 0;
}
.follow-us {
    margin-top: 20px;
}
.gmap-bg {
    background-color: #FFF1EB;
    padding: 100px 45px 100px;
}
.google-map {
    margin-bottom: -5px;
}
.google-map iframe {
    border-radius: 0;
}
.error-message {
    color: red;
  }
.cntc-inpt {
    margin-bottom: 4px;
}
.inpt-mrg {
    margin-bottom: 24px;
}
@media screen and (max-width: 768px){
    .contact-btn {
        margin-bottom: 20px;
    }
    .cnt-pd-lft {
        padding-left: 15px;
    }
}