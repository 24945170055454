.labor-banner {
    max-width: 1800px;
    background-image: url('/public/assets/imgs/department/aboutus-banner.png');
    background-repeat: no-repeat;
    background-position: center;
    padding: 150px 0px;
    margin: 0 auto;
    background-size: cover;
    position: relative;
    overflow: hidden;
}

.bt-title p {
    font-size: 18px;
    color: #000;
    margin-bottom: 5px;
    font-weight: 600;
    text-align: left;
}
.bt-text P {
    font-size: 18px;
    color: #454647;
    margin-bottom: 0;
    line-height: 27px;
    text-align: left;
}
.labor-collapse-imgs {
    height: 250px!important;
}
.facility-bg-color {
    background-color: #f6f6f6;
    padding: 100px 45px;
}