.awards-banner {
    background: url('/public/assets/imgs/awards/awards-banner.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 477px;
    text-align:start;
    display: flex;
    justify-content: center;
    align-items: center;
}
.awards-tabs {
    background: #fff;
}
.awards-bg-pill {
    display: flex;
    gap: 10px;
}
.nav-pills .custom-tab.active {
    background: #FFF1EB!important;
    color: #FB5220!important;
    border-top-left-radius: 5px!important;
    border-top-right-radius: 5px!important;
    border-bottom-left-radius: 0px!important;
    border-bottom-right-radius: 0px!important;
    border-left: 1px solid #FB5220;
    border-top: 1px solid #FB5220;
    border-right: 1px solid #FB5220;
    border-bottom: none;
}
.nav-pills .custom-tab {
    background-color: #FFF1EB;
    color: #454647;
    /* border-bottom: 3px solid #fff; */
    border-radius: 0;
}
.tab-awards-bg {
    background-color: #FFF1EB;
    padding: 80px 0;
    border-top: 1px solid #FB5220;
    margin-top: -1px;
}
.awards-tab-title {
    font-size: 32px;
    color: #333;
}
.awards-list {
    letter-spacing: .1px;
    font-size: 18px;
    color: #000;
    text-align: left;
}
.awards-list li {
    list-style-type: decimal;
    line-height: 30px;
}
/* timeline awardlist */
.timeline {
    margin-top: 30px;
}
.vertical-timeline {
    padding-left: 130px;
}
.award-list {
    border-left: 3px dashed #9e9e9e;
    position: relative;
    padding: 0 0 40px 30px;
}
.vertical-timeline .award-list::after {
    content: "";
    display: block;
    position: absolute;
    top: 6px;
    left: -7px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    border: 2px solid #FB5220;
    background-color: #fff;
}
.award-year {
    display: inline-block;
    border-radius: 4px;
    background-color: #FB5220;
    color: #fff;
    padding: 2px 12px;
    position: absolute;
    left: -124px;
    font-size: 18px;
}
.award-name {
    letter-spacing: .1px;
    font-size: 18px;
    color: #333;
    text-align: left;
    margin-bottom: 0;
    line-height: 28px;
}


@media screen and (max-width: 360px) {
    .awards-bg-pill {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
        gap: 10px;
    }
}