.molasses-over-wrp {
    max-width: 1800px;
    height: 700px;
    border-radius: 30px;
    background-image: url('/public/assets/imgs/products/bio-prdct1.jpg');
    background-repeat: no-repeat;
    background-position: center;
    padding: 120px 0px;
    margin: 0 auto;
    background-size: cover;
    position: relative;
    overflow: hidden;
    display: flex!important;
    align-items: center;
}
.company-overview .molasses-over-wrp::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* background: linear-gradient(90deg, #000000bc 40%, rgba(0, 0, 0, 0) 61.77%); */
    background: #00000081;
    z-index: 0;
}
.biocompost-over-wrp {
    max-width: 1800px;
    height: 700px;
    border-radius: 30px;
    background-image: url('/public/assets/imgs/products/bio-prdct2.jpeg');
    background-repeat: no-repeat;
    background-position: center;
    padding: 120px 0px;
    margin: 0 auto;
    background-size: cover;
    position: relative;
    overflow: hidden;
    display: flex!important;
    align-items: center;
}
.company-overview .biocompost-over-wrp::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* background: linear-gradient(90deg, #000000bc 40%, rgba(0, 0, 0, 0) 61.77%); */
    background: #00000081;
    z-index: 0;
}


@media only screen and (min-width: 1400px) and (max-width: 1599px) {
    .company-overview .cmpn-over-wrp, .molasses-over-wrp {
        height: 620px;
        padding: 100px 0;
    }
    .company-overview .cmpn-over-wrp, .biocompost-over-wrp {
        height: 620px;
        padding: 100px 0;
    }
}