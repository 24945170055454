.Farmer-banner-comingsoon {
    background: url('/public/assets/imgs/coming-soon-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 770px;
    text-align:start;
    display: flex;
    justify-content: center;
    align-items: center;
}

.comingsoon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}
.coming-soon {
    height: 100%;
}
.comingsoon-header {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 12px;
    color: #fff;
    text-align: center;
    margin-bottom: 30px;
}

.farmer-corner-wrp {
    max-width: 1800px;
    height: 700px;
    border-radius: 30px;
    background-image: url('/public/assets/imgs/cane-tractor.png');
    background-repeat: no-repeat;
    background-position: center;
    padding: 150px 0px;
    margin: 0 auto;
    background-size: cover;
    position: relative;
    overflow: hidden;
}
.company-overview .farmer-corner-wrp::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, #000000 20%, rgba(0, 0, 0, 0) 61.77%);
    z-index: 0;
}
.rounded-img {
    width: 60px;
    height: 100%;
    margin-bottom: 20px;
}
.farmerc-title  {
    font-size: 60px;
    line-height: 80px;
    color: #fff;
    word-break: normal;
}
.farmerc-title span {
    color: #FB5220;
}
.farmerc-subtxt {
    font-size: 20px;
    line-height: 26px;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 40px;
}

@media (max-width:510px) {
    .farmer-corner-wrp {
        padding: 50px 0;
        background-position-x: 82% !important;
    }
    .company-overview .farmer-corner-wrp::after {
        background: linear-gradient(90deg, #000000 20%, rgba(0, 0, 0, 0) 121.77%);
    }
}