.computer-banner {
    max-width: 1800px;
    background-image: url('/public/assets/imgs/department/aboutus-banner.png');
    background-repeat: no-repeat;
    background-position: center;
    padding: 150px 0px;
    margin: 0 auto;
    background-size: cover;
    position: relative;
    overflow: hidden;
}
.custom-accordoin-btn {
    color: transparent;
    background-color:transparent!important;
    border-radius: 0px!important;
    padding: 25px 16px;
}
.custom-accordoin-btn h4 {
    margin-bottom: 0;
}
.custom-accordoin-btn:focus {
    box-shadow:none;
}
.accordion-body{
    border-top: 1px solid #ddd;
    padding: 28px 16px;
}
.accordion-body p {
    padding-left: 10px;
}
.custom-item {
    border-top: 0;
    border-left: 0;
    border-right: 0;
}
.collapse-imgs {
    width: 100%;
    object-fit: cover;
    height: 100%!important;
    border-radius: 10px;
}
.cmputer-imgs {
    height: 250px!important;
    object-position: 20% 20%;
}

@media screen and (max-width : 768px) { 
    .collapse-imgs {
        margin-bottom: 10px;
    }
    .bt-text {
        margin-top: 15px;
    }
}


