.majorproduct-banner {
    background: url('/public/assets/imgs/products/products-banner.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 477px;
    text-align:start;
    display: flex;
    justify-content: center;
    align-items: center;
}
.prd-rads {
    border-radius: 0;
}
.cogeneration-over-wrp {
    max-width: 1800px;
    height: 700px;
    border-radius: 30px;
    background-image: url('/public/assets/imgs/products/cogene1.jpg');
    background-repeat: no-repeat;
    background-position: center;
    padding: 120px 0px;
    margin: 0 auto;
    background-size: cover;
    position: relative;
    overflow: hidden;
    display: flex!important;
    align-items: center;
}
.company-overview .cogeneration-over-wrp::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* background: linear-gradient(90deg, #000000bc 40%, rgba(0, 0, 0, 0) 61.77%); */
    background: #00000081;
    z-index: 0;
}
.sugar-over-wrp {
    max-width: 1800px;
    height: 700px;
    border-radius: 30px;
    background-image: url('/public/assets/imgs/products/sugar.png');
    background-repeat: no-repeat;
    background-position: center;
    padding: 120px 0px;
    margin: 0 auto;
    background-size: cover;
    position: relative;
    overflow: hidden;
    display: flex!important;
    align-items: center;
}
.company-overview .sugar-over-wrp::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* background: linear-gradient(90deg, #000000bc 40%, rgba(0, 0, 0, 0) 61.77%); */
    background: #00000081;
    z-index: 0;
}
.distillery-over-wrp {
    max-width: 1800px;
    height: 700px;
    border-radius: 30px;
    background-image: url('/public/assets/imgs/products/dist1.jpg');
    background-repeat: no-repeat;
    background-position: center;
    padding: 120px 0px;
    margin: 0 auto;
    background-size: cover;
    position: relative;
    overflow: hidden;
    display: flex!important;
    align-items: center;
}
.company-overview .distillery-over-wrp::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* background: linear-gradient(90deg, #000000bc 40%, rgba(0, 0, 0, 0) 61.77%); */
    background: #00000081;
    z-index: 0;
}
.description {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0;
    color: #ebebeb;
}
.distilery-list {
    letter-spacing: .1px;
    font-size: 20px;
    color: #ebebeb;
    text-align: left;
    padding-left: 18px;
}
.distilery-list li {
    list-style-type: decimal;
    line-height: normal;
}
.prdcts-btns {
    top: -140px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
    .company-overview .cmpn-over-wrp, .cogeneration-over-wrp {
        height: 620px;
        padding: 100px 0;
    }
    .company-overview .cmpn-over-wrp, .sugar-over-wrp {
        height: 620px;
        padding: 100px 0;
    }
    .company-overview .cmpn-over-wrp, .distillery-over-wrp {
        height: 620px;
        padding: 100px 0;
    }
}

@media only screen and (max-width: 768px) {
    .mob-distilery-list {
        font-size: 16px;
        color: #454647;
        text-align: left;
        padding: 0 0px 0 19px;
    }
    .mob-distilery-list li {
        list-style-type: decimal;
    }
}