.accounts-banner {
    max-width: 1800px;
    background-image: url('/public/assets/imgs/department/aboutus-banner.png');
    background-repeat: no-repeat;
    background-position: center;
    padding: 150px 0px;
    margin: 0 auto;
    background-size: cover;
    position: relative;
    overflow: hidden;
}
.agriculture-multimg .slick-dots {
    bottom: 1.5rem;
    right: 1.2rem;
    /* width: auto; */
    text-align: right;
}
.agriculture-multimg .slick-dots li button {
    border-radius: 50%;
}
.agriculture-multimg .slick-dots li button:before {
    opacity: 1;
    background-color: #f5a5a5!important;
    color: transparent!important;
    width: 12px;
    height: 12px;
    border-radius: 50%;
}
.agriculture-multimg .slick-dots li.slick-active button:before{
    opacity: 1;
    /* color: #141F39; */
    background-color: #FB5220!important;
}
.agriculture-multimg .slick-dots li button:hover {
    opacity: 1;
    color: #99482f;
}

.red {
    color: rgb(214, 38, 38)!important;
}