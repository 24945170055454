.Tenders-headbanner {
    position: sticky;
    top: 0;
    bottom: 0;
    z-index: -9;
}
.Tenders-banner{
    background: url('/public/assets/imgs/tender-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 477px;
    text-align:start;
    display: flex;
    justify-content: center;
    align-items: center;
}
.headingtenders {
    color: #fff;
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 12px;
}
.bg-container {
    background-color: transparent !important;
    margin-bottom: 0px;
}
.brdm-itm a {
    color: #fff;
}
.brdm-itm.active {
    color: #FB5220;
}
.brdm-itm+.brdm-itm::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #ffffff;
    content: "->";
}
.username {
    /* display: none!important; */
}
.username {
    display: flex;
    align-items: center;
    justify-content: center;
}
.username h2 span {
    color: #FB5220;
}
.tender-table {
    z-index: 9;
    background-color: #FFF1EB;
    padding: 70px 45px;
}
.tendermain-table {
    background-color: #fff;
    border-radius: 30px;
    padding: 40px 30px;
}
.login-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px;
}
.login-register {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
}
.appl-text {
    margin-bottom: 0;
    font-size: 14px;
    color: #000;
    margin-right: 5px;
}
.tender-login {
    background-color: #FB5220;
    color: #fff;
    padding: 7px 20px;
    margin-right: 5px;
}
.tender-login:hover {
    background-color: #f78866;
}
.tender-register {
    background-color: #fff;
    border: 1px solid #000;
    padding: 7px 20px;
}
.tender-register:hover {
    background-color: #e4e4e4;
    color: #000;
}
.Download {
    background-color: #fff;
    border: 1px solid #FB5220;
    color: #FB5220;
    padding: 8px;
    font-size: 11px;
    font-weight: 500;
}
.Download:hover {
    background-color: #fafafa;
    border: 1px solid #FB5220;
    color: #FB5220;
}
.apply-now {
    background-color: #fb532011;
    border: 1px solid #FB5220;
    font-size: 11px;
    font-weight: 500;
    padding: 8px;
}
.apply-now:hover {
    background-color: #FB5220;
    color: #fff;
}

.search-inp {
    margin-bottom: 0!important;
}
.search-inp input{
    height: 55px!important;
}

.table>thead {
    vertical-align: middle;
}
.table>thead>tr>th {
    padding-left: 15px;
}
.table>tbody {
    vertical-align: middle;
}
.table>tbody>tr>td {
    padding-left: 15px;
}
.th-height {
    background-color: #000;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.tr-height {
    height: 60px;
    font-size: 16px;
}

.no-record-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
.not-found-img {
    width: 180px;
    height: auto;
}
.no-record-found p {
    font-size: 16px;
    font-weight: 400;
    color: #454647;
}
.table-pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cstm-link {
    color: #FB5220!important;
}

.active>.page-link {
    background-color: #FB5220!important;
    border: 1px solid #FB5220;
    color: #fff!important;
}


.confirm-bg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.confirm-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px
    ;
}
.cancel-logout {
    background-color: #fff;
    border-color: #000;
    color: #000;
    padding: 10px 40px;
}
.cancel-logout:hover {
    background-color: #e4e4e4;
    color: #000;
}

@media screen and (max-width : 1080px) {
    .tender-table {
        padding-left: 30px;
        padding-right: 30px;
    }
    .login-search {
        display: flex;
        flex-direction: column ;
        gap: 15px;
        align-items: stretch;
    }

    .login-register {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .search-inp {
        width: 100%!important;
    }
}
@media screen and (max-width : 480px) {
    .tender-table {
        padding-left: 5px;
        padding-right: 5px;
    }
    .tendermain-table {
        padding-left: 5px;
        padding-right: 5px;
    }
    .table-pagination {
        display: flex;
        flex-direction: column ;
        gap: 15px;
        align-items:center;
    }
}