.investor-subtitle {
    font-size: 30px;
    font-family: 'semibold-font',sans-serif;
    color: #000;
    font-weight: 600;
}
.civil-img {
    position: relative;
}
.image-name {
    position: absolute;
    width:100%;
    left: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.5s;
    z-index: 1;
    background: #000000e0;
    padding: 15px 10px;
    border-radius: 0 0 10px 10px;
}
.image-name p {
    color: #fff;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
}
.mrg-b {
    margin-bottom: 65px;
}
.txt-overflw {
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.upsa-img {
    height: -webkit-fill-available;
}