.ourteam-banner {
    background: url('/public/assets/imgs/aboutus-img/aboutus-banner.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 477px;
    text-align:start;
    display: flex;
    justify-content: center;
    align-items: center;
}
.leadership1-bg {
    position: relative;
    background: url('/public/assets/imgs/ourteam-img/bapu-lad.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    border-right: 1px solid #fff;
    opacity: 0.6;
    transition: all 0.3s ease-in-out;
}
.leadership1-bg::after {
    content: "";
    display: block;
    position: absolute;
    /* bottom: 0; */
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 40.03%, #000 100%);
    /* border-radius: 24px; */
    pointer-events: none;
}
.leadership1-bg:hover {
    transition: all 0.3s ease-in-out;
    opacity: 1;
}
.leadership2-bg {
    position: relative;
    background: url('/public/assets/imgs/ourteam-img/arun-lad.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    border-right: 1px solid #fff;
    opacity: 0.6;
    transition: all 0.3s ease-in-out;
}
.leadership2-bg::after {
    content: "";
    display: block;
    position: absolute;
    /* bottom: 0; */
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 40.03%, #000 100%);
    /* border-radius: 24px; */
    pointer-events: none;
}
.leadership2-bg:hover {
    transition: all 0.3s ease-in-out;
    opacity: 1;
}
.leadership3-bg {
    position: relative;
    background: url('/public/assets/imgs/ourteam-img/our-team-sharad.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    border-right: 1px solid #fff;
    opacity: 0.6;
    transition: all 0.3s ease-in-out;
}
.leadership3-bg::after {
    content: "";
    display: block;
    position: absolute;
    /* bottom: 0; */
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 40.03%, #000 100%);
    /* border-radius: 24px; */
    pointer-events: none;
}
.leadership3-bg:hover {
    transition: all 0.3s ease-in-out;
    opacity: 1;
}
.scroll-content {
    height: 400px;
    overflow-y: scroll;
    padding-left: 20px;
}
.mngmt-membrs {
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 25px;
}
.mngmt-membrs img {
    width: 100%;
    height: 380px;
    object-fit: cover;
}
.mngmt-membrs .membrs-info {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 23px;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.5s;
    z-index: 1;
}
.member-name {
    color: #fff;
    font-size: 24px;
    line-height: normal;
    padding-left: 10px;
    margin-bottom: 0;
}
.member-pos {
    margin-bottom: 0;
    color: #fff;
    font-size: 16px;
    line-height: normal;
    padding-left: 10px;
}
.mngmt-membrs::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 40.03%, #000 100%);
    border-radius: 15px;
    pointer-events: none;
}
.leader-name-modal {
    color: #FB5220;
    font-size: 24px;
    line-height: normal;
    margin-bottom: 8px;
    font-weight: 600;
    margin-bottom: 0;
}
.leader-pos-modal {
    color: #000;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 16px;
    font-weight: 500;
}
.close{
    position: absolute;
    right: 15px;
    z-index: 9;
}

@media screen and (max-width : 768px) {
    .leadership1-bg, .leadership2-bg {
        border-bottom: 1px solid #fff;
        border-right: 0;
    }
}