.home-banner {
    position: sticky;
    top: 0;
    bottom: 0;
    z-index: -9;
}
.home-banner .slider .banner-img{
    position: sticky;
    z-index: -9;
    top: 0;
    bottom: 0;
    /* position: absolute; */
    background-size:auto;
    background-position: center;
    width: 100%;
    height: 700px;
    margin-bottom: 30px;
    text-align: left;
}
.section-heading {
    bottom: 31px;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    left: 0;
    background-color: rgba(0, 0, 0, 0.600);
}
.inner-sec {
    /* margin-left: 70px; */
}
.homebanner-sbtxt {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 1px;
    color: #fff;
}
.homebanner-heading {
    font-size: 60px;
    font-weight: 700;
    line-height: 80px;
    max-width: 600px;
    color: #fff;
    text-transform: capitalize;
    margin-bottom: 15px;
}
.learn-more {
    background-color: #fff;
    color: black;
    border: 1px solid #000;
    padding: 12px 40px;
}
.learn-more:hover {
    background-color: #FB5220;
    color: #fff;
}
.Arrow-1 {
    margin-left: 15px;
}

/* simple-idea */
.simple-idea {
    z-index: 9;
    background-color: #fff;
    padding-top: 50px;
}
.pdr {
    padding-right: 10px;
}
.idea-subtxt {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 14px;
    color: #000;
    text-align: center;
}
.idea-title {
    margin: 0 auto;
    color: #FB5220;
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    border-left: 3px solid #FB5220;
    text-align: center;
    width: fit-content;
    padding-left: 15px;
}
.simple-idea-text h2{
    font-size: 1.8rem;
    text-align: left!important;
}
.simple-idea-text h1 {
    font-weight: 600;
    text-align: left!important;
}
.idea-para {
    margin-top: 15px;
    font-size: 18px;
    font-weight: 500px;
    line-height: 27px;
    color: #454647;
    text-align: center;
    margin-bottom: 30px;
}
.learn-more-2 {
    background-color: #FB5220;
    color: #fff;
    padding: 12px 40px;
}
.learn-more-2:hover {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
}
.view-more {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.simpleidea-img {
    /* margin-top: 15px; */
    width: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
}

/* company overview */
.company-overview {
    background-color: #FFF1EB;
    padding: 80px 50px;
}
.cmpn-over-wrp{
    max-width: 1800px;
    height: 700px;
    border-radius: 30px;
    background-image: url('/public/assets/imgs/performance-data.jpg');
    background-repeat: no-repeat;
    background-position: center;
    padding: 150px 0px;
    margin: 0 auto;
    background-size: cover;
    position: relative;
    overflow: hidden;
}
.cmpn-details {
    position: relative;
    z-index: 2;
}
.pd-subtxt {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 14px;
    color: #fff;
    text-align: left;
}
.co-title {
    border-left: 3px solid #fb5220;
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    padding-left: 15px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.data-row {
    margin-top: 60px;
    column-gap: 20px;
}
.glass-bg {
    border-radius: 20px;
    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 20px );
    -webkit-backdrop-filter: blur( 20px );
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    padding: 22px 24px;
    text-align: center;
    margin: 0 10px;
}
/* .gap-itm {
    padding: 0 8px;
    margin: 0 5px;
} */
.crushing {
    font-size: 18px;
    line-height: normal;
    margin-bottom: 18px;
    color: #fff;
    height: 50px;
}
.crushing-value {
    font-size: 40px;
    line-height: normal;
    margin-bottom: 25px;
    color: #fff;
}
.fy-year {
    font-size: 18px;
    line-height: normal;
    color: #fff;
    margin-bottom: 0;
}
.company-overview .cmpn-over-wrp::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* background: linear-gradient(90deg, #000000bc 10%, rgba(0, 0, 0, 0) 61.77%); */
    background: #00000080;
    z-index: 0;
}

/* Tenders */
.investor-bg {
    padding: 100px 50px;
    background-image: url('/public/assets/imgs/investors-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}
.investor-title {
    color: #333;
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    border-left: 3px solid #FB5220;
    text-align: left;
    width: fit-content;
    padding-left: 15px;
    font-family: 'Medium-font', sans-serif;
}
.tender-sbtitle {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 0;
}
.tender-sbtxt {
    font-size: 14px;
    line-height: 30px;
}
.tender-block {
    display: flex;
    align-items:center;
    justify-content: flex-start;
    margin-bottom: 20px;
    cursor: pointer;
}
.pdf-icon {
    width: 80px;
}
.datedesc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.tenderdate {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 5px;
}
.tendarname {
    font-size: 14px;
    line-height: normal;
    width: auto;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #454647;
    margin-bottom: 10px;
}
.notice-bg {
    background-color: #FFF1EB;
    border: 1px solid #FB5220;
    border-radius: 10px;
    padding: 30px 25px;
}
.notice-board {
    font-size: 26px;
    margin-bottom: 25px;
}
.notice-block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;
}
.board-block {
    background-color: #fff;
    border-radius: 30px;
    margin-right: 10px;
    padding: 10px;
}
.board-icon {
    width: 34px;
}
.notice-namedate {
    display: flex;
    flex-direction: column;
}
.noticename {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 5px;
    width: auto;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.noticedate {
    font-size: 14px;
    line-height: normal;
    color: #454647;
}

/* facility */
.facility-bg {
    background-color: #fff;
    padding: 50px 0;
}
.facility-sbtxt {
    font-size: 14px;
    color: #454647;
}
.facility-main-title {
    margin: 0 auto;
    color: #333;
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    border-left: 3px solid #FB5220;
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 15px;
    font-family: 'Medium-font', sans-serif;
}
.facility-main-sbtxt {
    text-align: center;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 40px;
}
.faciltiy-title {
    padding: 40px 70px !important;
}
.mrg-0 {
    margin: 0;
}
.pad-0 {
    padding: 0;
}
.pad {
    padding: 40px 70px!important;
    border-radius: 0!important;
}
.nav-pills .nav-link.active p{
    color: #fff;
}
.nav-pills .nav-link.active h3{
    color: #fff;
}
.nav-pills .nav-link h3 {
    color: #FB5220;
}
.facility-title {
    text-align: left;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 12px;
}
.home-facility-title {
    font-weight: 600;
    margin-bottom: 20px;
}
.home-facility-sbtxt {
    letter-spacing: 0px;
    line-height: 27px;
    font-weight: 500;
}
.facility-sbtxt {
    text-align: left;
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 0px;
}
.facility-img1 {
    width: 100%;
    height: 719px;
    object-fit: cover;
    border-radius: 20px 0 0 20px;
}

.facility-card {
    width: 100%;
    display: inline-block;
    background-color: #fff1eb;
    margin-bottom: 50px;
    border-radius: 10px;
    transition: all 0.8s ease;
}
.mobile-facilityimg {
    height: 240px;
    object-fit: cover;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
}
.facility-content {
    padding: 20px 20px;
}
.facility-heading h4 {
    font-size: 20px;
    color: #FB5220;
    margin-bottom: 5px;
    line-break: 30px;
}
.facility-preview p {
    font-size: 14px;
    line-height: normal;
    margin-bottom: 0;
    opacity: 0.8;
}

.bapulad-over-wrp {
    max-width: 1800px;
    height: 700px;
    border-radius: 30px;
    background-image: url('/public/assets/imgs/bapu-lad.png');
    background-repeat: no-repeat;
    background-position: center;
    padding: 120px 0px;
    margin: 0 auto;
    background-size: cover;
    position: relative;
    overflow: hidden;
}
.company-overview .bapulad-over-wrp::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, #000000bc 30%, rgba(0, 0, 0, 0) 61.77%);
    z-index: 0;
}
.leadership-over-wrp {
    max-width: 1800px;
    height: 700px;
    border-radius: 30px;
    background-image: url('/public/assets/imgs/arun-lad(blur).png');
    background-repeat: no-repeat;
    background-position: center;
    padding: 120px 0px;
    margin: 0 auto;
    background-size: cover;
    position: relative;
    overflow: hidden;
}
.company-overview .leadership-over-wrp::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, #000000bc 30%, rgba(0, 0, 0, 0) 61.77%);
    z-index: 0;
}
.sharad-lad-wrp {
    max-width: 1800px;
    height: 700px;
    border-radius: 30px;
    background-image: url('/public/assets/imgs/Sharad-lad-home.png');
    background-repeat: no-repeat;
    background-position: center;
    padding: 120px 0px;
    margin: 0 auto;
    background-size: cover;
    position: relative;
    overflow: hidden;
}
.company-overview .sharad-lad-wrp::after  {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, #000000bc 30%, rgba(0, 0, 0, 0) 61.77%);
    z-index: 0;
}
.leadership-details {
    position: relative;
    z-index: 2;
}
.leadershipsec-title {
    color: #cecece;
    font-size: 26px;
    /* font-weight: 700; */
    line-height: 60px;
    border-left: 3px solid #FB5220;
    text-align: left;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 15px;
    letter-spacing: 1px;
}
.quote {
    font-size: 46px;
    line-height: 60px;
    color: #ffffff;
    margin-bottom: 20px;
}
.headname {
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 1px;
    color: #fff;
}
.poisition {
    font-size: 16px;
    line-height: 25px;
    color: #fff;
    margin-bottom: 19px;
}
.overview {
    font-size: 14px;
    color: #fff;
    margin-bottom: 19px;
    line-height: 22px;
}
.hom-lm-btn {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}
.social-links {
    display: flex;
    align-items: center;
}
.social-icon {
    width: 40px;
    margin-right: 15px;
}
.btns {
    display: block;
    position: relative;
    top: 140px;
    right: 30px;
    z-index: 4;
}

.prev-btn, .nxt-btn {
    margin-right: 10px;
    height: 50px;
    width: 50px;
    text-align: center;
    background-color: #FB5220;
    border-radius: 5px;
    border: none;
}

.slick-arrow-prev {
    width: 10px;
}
.slick-arrow-nxt {
    width: 10px;
    transform: rotate(180deg);
}
.mob-title {
    color: #333;
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    border-left: 3px solid #FB5220;
    text-align: left;
    width: fit-content;
    padding-left: 15px;
}
.leadership-content {
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #ddd;
}

.leadership-quote h4{
    font-size: 30px;
    line-height: 35px;
    color: #000;
    margin-bottom: 10px;
}
.leadership-namepos .name {
    color: #000;
    font-size: 20px;
    line-height: normal;
    margin-bottom: 0;
}
.leadership-namepos .position {
    color: #000;
    font-size: 18px;
    line-height: normal;
    margin-bottom: 0;
}
.leadership-preview {
    font-size: 16px;
    color: #000;
    line-height: normal;
    margin-bottom: 0;
    opacity: 0.8;
}
.social-links a:-webkit-any-link:focus-visible {
    outline-offset: 0;
    outline: 0!important;
}

/* newsroom-gallery-famercorner section */
.ngfc-bg {
    background-color: #fff;
    padding-top: 100px;
    padding-bottom: 50px;
    padding-left: 65px;
    padding-right: 65px;
}
.newsmrg {
    margin-bottom: 0px;
}
.fc {
    padding-right: 20px;
}
.kg {
    padding-left: 20px;
}
.pressconf {
    border-bottom: 1px solid #000;
    padding-bottom: 12px;
    font-size: 18px;
    color: #454647;
    line-height: normal;
    margin-bottom: 10px;
    width: fit-content;
}
.news-title {
    font-size: 24px;
    line-height: normal;
    color: #444;
    margin-bottom: 18px;
    /* width: 500px    ; */
}
.fc-title {
    font-size: 18px;
    line-height: normal;
    color: #000;
    margin-bottom: 18px;
}
.newsroom-img {
    width: 100%;
    border-radius: 10px;
}
.mins-mrg {
    margin-bottom: -100px;
    margin-left: -30px;
}


@media only screen and (min-width: 768px) and (max-width: 1920px) {
    .company-overview .cmpn-over-wrp, .leadership-over-wrp, .sharad-lad-wrp, .bapulad-over-wrp {
        height: auto;
        padding: 100px 0;
    }
    .minus-mrg {
        margin-bottom: -90px;
    }
}

@media (min-width: 300px) and (max-width: 768px) {
    .company-overview {
        padding-left: 10px;
        padding-right: 10px;
    }
    .company-overview .cmpn-over-wrp{
        border-radius: 20px;
        padding-top: 25px;
        padding-bottom: 25px;
        height: 465px;
        background-position: center;
    }
    .data-row {
        margin-top: 30px;
    }
}

@media screen and (max-width: 1400px) {
    .mins-mrg {
        margin-bottom: -150px;
        margin-left: -30px;
    }
    .pad {
        padding: 30px 70px !important;;
    }
}

@media (max-width: 768px) {
    .investor-bg {
        padding-left: 10px;
        padding-right: 10px;
    }
    .mob-mr {
        margin-bottom: 20px;
    }
    .notice-bg, .ngfc-bg {
        padding-left: 15px;
        padding-right: 15px;
    }
    .mins-mrg {
        margin-bottom: 0;
        margin-left: 0;
    }
    .simpleidea-img {
        margin-top: 30px;
    }

}

@media (min-width: 768px) {
    .mobile-view {
        display: none;
    }
    .leadership-mobile-view {
        display: none;
    }
}

@media (max-width: 768px) {
    .inner-sec {
        margin-left: 10px;
    }
    .homebanner-sbtxt {
    }
    .homebanner-heading {
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 30px;
    }
    .learn-more {
        padding: 12px 30px;
        font-size: 14px;
    }
    .desktop-view{
        display: none;
    }
    .mobile-view {
        display: block;
        margin-top: 30px;
    }
    .hom-lm-btn {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        color: #000!important;
        font-weight: 600;
    }
    .hom-lm-btn img {
        filter: grayscale(0.5);
    }
    .leadership-desktop-view {
        display: none;
    }
    .leadership-mobile-view {
        display: block;
    }
    .btns {
        display: block;
        position: relative;
        top: 0;
        right: 0;
        z-index: 4;
    }
    .prev-btn, .nxt-btn {
        margin-top: -10px;
    }
    .news-title {
        width: 280px;
    } 
}
