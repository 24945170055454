.main-home-banner {
    background: url('/public/assets/imgs/Ks-videobg.gif');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 700px;
    text-align:start;
    display: flex;
    justify-content: center;
    align-items: center;
}


.simple-idea-text h2 {
    font-size: 24px;
}
.pdr {
    margin-bottom: 35px;
    padding-right: 60px;
}
/* .simple-idea-parallex {
    position:sticky;
    top: 0;
    bottom: 0;
    z-index: 1;
}
.company-overview-parallex {
    position:sticky;
    top: 0;
    bottom: 0;
    z-index: 2;
} */
.new-compnayover-bg {
    padding: 100px 0;
    position: relative;
    background-image: url('/public/assets/imgs/new-performance-bg.png');
}

.co-newtitle {
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    border-left: 3px solid #FB5220;
    text-align: left;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 15px;
    font-family: 'Medium-font', sans-serif;
}
.pd-newmintxt {
    text-align: left;
    font-size: 20px;
    line-height: normal;
    margin-bottom: 20px;
    color: #999;
}
.pd-newsubtxt {
    text-align: left;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 40px;
    color: #aaa;
}
.icon-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.coc-icon {
    width: 34px;
    opacity: .7;
}
.company-overview-card {
    /* border-right: 2px solid #ddd; */
    transition: all 0.3s;
}
.last {
    border: none;
}
.company-overview-card :hover > h4 {
    color: #FB5220;
    transition: all 0.3s;
    cursor: pointer;
}
.crushing-value-new {
    font-size: 48px;
    line-height: normal;
    margin-bottom: 5px;
    color: #fff;
}
.crushing-sbtxt {
    font-size: 18px;
    line-height: normal;
    margin-bottom: 18px;
    color: #aaa;
    height: 50px;
}
.mrg-common {
    margin-top: 40px;
}


.ip-mb {
    margin-bottom: 23px;
}
.si-fill-img {
    width: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    height: -webkit-fill-available;
    object-fit: cover;
}
.cmpn-over-wrp-new {
    max-width: 1800px;
    height: 700px;
    border-radius: 30px;
    background-image: url('/public/assets/imgs/department/enginnering/Eng-img8.jpeg');
    background-repeat: no-repeat;
    background-position: center;
    padding: 150px 0px;
    margin: 0 auto;
    background-size: cover;
    position: relative;
    overflow: hidden;
}
.farmer-corner-wrp-new {
    max-width: 1800px;
    height: 600px;
    border-radius: 0px;
    background-image: url('/public/assets/imgs/cane-tractor.png');
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    padding: 150px 0px;
    margin: 0 auto;
    background-size: cover;
    position: relative;
    overflow: hidden;
}
.farmer-corner-new .farmer-corner-wrp-new::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* background: linear-gradient(90deg, #000000bc 10%, rgba(0, 0, 0, 0) 61.77%); */
    background: #0000009f;
    z-index: 0;
}
.company-overview .cmpn-over-wrp-new::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* background: linear-gradient(90deg, #000000bc 10%, rgba(0, 0, 0, 0) 61.77%); */
    background: #0000009f;
    z-index: 0;
}
.co-newbg {
    padding: 0;
}
.no-rad {
    border-radius: 0;
}
.new-facility-bg {
    background-color: #fff;
    padding: 100px 0;
    position: relative;
}
.facility-bg-pill {
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
}
.facility-icon {
    width: 30px;
}
.img-hght {
    height: 100%;
}
.tab-facility-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.nav-pills .facility-navlink-new {
    background: none!important;
    padding: 25px 30px!important;
    border-bottom: 1px solid #f9d9cc;
    color: #454647!important;
    border-radius: 0;
}
.nav-pills .facility-navlink-new.active {
    border: 1px solid #f9d9cc !important;
    font-weight: 700;
    border-radius: 5px!important;
    background-color: #FFF1EB !important;
    padding: 15px 30px;
}
.nav-pills .facility-navlink-new h3 {
    text-align: left;
    font-size: 20px!important;
    color: #454647;
    font-weight: 500;
    margin-bottom: 0;
}
.nav-pills .facility-navlink-new p {
    display: none;
}
.nav-pills .facility-navlink-new.active h3 {
    color: #FB5220;
    text-align: left;
    margin-bottom: 0;
}
.nav-pills .facility-navlink-new.active p {
    display: block;
    color: #777;
    background-color: transparent;
    padding-top: 25px;
    padding-bottom: 15px;
    padding-left: 0;
    border-radius: 5px;
}
.nav-pills .facility-navlink-new.active > .tab-facility-name {
    background-color: transparent;
    padding-left: 0;
    padding-top: 15px;
    padding-bottom: 30px;
    border-bottom: 3px dashed #f6d5cc;
}
.tab-padding {
    padding-right: 20px;
    height: 100%;
}
.new-facility-title {
    text-align: left;
    font-size: 30px;
    line-height: 45px;
    color: #FB5220;
}
.new-home-facility-title {
    margin-bottom: 20px;
}
.new-facility-sbtxt {
    text-align: left;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 0px;
}
.new-home-facility-sbtxt {
    letter-spacing: 0px;
    font-weight: 500;
}
.new-facility-img1 {
    border-radius: 10px;
    height: 100%;
    object-fit: cover;
    width: 100%;
}
/* .new-facility-bg ::after {
    content: "";
    width: 500px;
    height: 500px;
    background-image: url('/public/assets/imgs/Dots_businesses.png');
    background-repeat: no-repeat;
    background-position: top left;
    position: absolute;
    bottom: 50px;
    right: 100px;
    z-index: 1;
} */

.leadership-bapulad-bg {
    background-image: url('/public/assets/imgs/bapu-lad.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}
.leadership-arunlad-bg {
    background-image: url('/public/assets/imgs/new-Arunlad\(blur\).png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position-x: 50%;
}
.leadeship-sharadlad-bg {
    background-image: url('/public/assets/imgs/new-sharadlad.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position-x: 50%;
}
.info-bg {
    background-color: #FFF1EB;
}
.info-bg2 {
    background-color: #fff;
}
.info-container {
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 100px;
}
.new-leadershipsec-title {
    color: #454647;
    font-size: 30px;
    font-weight: 500;
    line-height: 60px;
    border-left: 3px solid #FB5220;
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 15px;
    font-family: 'Medium-font', sans-serif;
}
.new-quote {
    color: #333;
}
.new-headname {
    color: #FB5220;
}
.new-position {
    color: #444;
}
.new-overview {
   color: #777;
}
.lead-lm-btn {
    background-color: transparent;
    color: #333;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #333;
    border-radius: 0;
    font-weight: 600;
    transition: all 0.3s;
}
.lead-lm-btn:hover {
    border-bottom: 1px solid #FB5220;
    color: #FB5220;
    transition: all 0.3s;
}

.new-cmp-ovr {
    background: none;
    padding: 0;
}
.ldr-bg {
    background: #FFF1EB;
}
.tender-bg {
    padding: 100px 50px;
    background-image: url('/public/assets/imgs/tender-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.newtender-sbtxt {
    text-align: left;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 40px;
}
.tender-card {
    width: 100%;
    display: inline-block;
    background-color: #fff1eb;
    margin-bottom: 50px;
    border-radius: 10px;
    transition: all 0.8s ease;
    border: 1px solid #ddd;
}
.tender-mrg-btm {
    margin-bottom: 70px;
}
.notice-hgt {
    height: 100%;
}
.nb-mrg {
    margin-bottom: 10px;
}
.new-borad-bullet {
    padding: 5px;
    background-color: #FB5220;
    border-radius: 30px;
    margin-right: 0;
}
.new-tender-card {
    /* position: relative; */
    width: 100%;
    border-radius: 10px;
    transition: all 0.5s ease;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-image: url('/public/assets/imgs/bg-logo-img\ \(1\).png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    /* box-shadow: 2px 4px 8px 1px #00000014; */
}
.new-tender-card:hover {
    border: 1px solid #bbbbbb;
    transition: all 0.5s ease;
    transform: translateY(-10px);
}
.bg-logo-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 180px;
    /* opacity: 0.5; */
    object-fit: contain;
    transition: all .3s;
}
.tender-img-main {
    height: 140px;
    object-fit: cover;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
}
.tender-content {
    /* background-color: #fff; */
    /* padding: 30px 20px 0; */

}
.pd-datedesc {
    padding: 30px 20px 0;
}
.new-tenderdate {
    font-size: 20px;
    line-height: normal;
    margin-bottom: 5px;
}
.new-tendarname {
    font-size: 16px;
    line-height: normal;
    width: auto;
    height: 55px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #454647;
    margin-bottom: 10px;
}
.tendartitle {
    font-size: 20px;
    width: auto;
    height: 55px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #333;
    margin-bottom: 8px;
    line-height: 27px;
}
.tendardate {
    color: #6d6d6d;
    line-height: normal;
    margin-bottom: 5px;
}
.tender-desc {
    padding: 0 20px;
}
.new-tendar-desc {
    font-size: 16px;
    color: #999;
    line-height: 24px;
    margin-top: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.tender-divider {
    content: "";
    height: 1.5px;
    width: 100%;
    background-color: #ddd;
    margin-top: 60px;
    padding: 0 20px;
    /* margin-bottom: 25px; */
}
.dwnlod-btn {
    /* background-color: #fff; */
    color: #FB5220;
    /* padding: 12px 40px; */
    font-weight: 700;
    padding: 15px 0;
}
.dwnlod-btn:hover {
    background-color: #FB5220;
    color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.new-ngfc-bg {
    background-color: #FFF1EB;
    padding: 100px 65px;
}
.other-news {
    padding-left: 15px;
}
.main-news a {
    text-decoration: none;
}
.main-news :hover * {
    color: #FB5220!important;
    transition: all 0.3s;
}
.other-news a {
    text-decoration: none;
}
.other-news :hover * {
    color: #FB5220!important;
    transition: all 0.3s;
}
.other-news1 {
    display: flex;
    align-items: center;
    gap: 15px;
}
.bg-gray {
    background-color: #cacaca;
    height: 1px;
}
.newsroom-img {
    width: 100%;
    height: 330px;
    object-fit: cover;
}
.other-news-img {
    max-width: 150px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
}
.news-sbtitle {
    font-size: 20px;
    line-height: 32px;
    color: #444;
}
.farmercorner-title {
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    border-left: 3px solid #FB5220;
    text-align: left;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 15px;
    font-family: 'Medium-font', sans-serif;
}
.fc-sbtitle {
    font-size: 18px;
    line-height: 27px;
    color: #FFF1EB;
    margin-bottom: 18px;
}
.no-pd {
    padding: 0;
}
.mrg-btm {
    margin-bottom: 24px;
}
.gallery-sbtitle {
    font-size: 18px;
    line-height: normal;
    color: #444;
    margin-bottom: 40px;
}
.tender-mobile-gap {
    display: none;
}

@media screen and (max-width: 768px) {
    .mob-inner-sec {
        margin-right: 20px;
    }
    .inner-sec {
        margin-left:0 ;
    }
    .pdr {
        padding-right: 0;
    }
    .ovr-pd-lft {
        padding-left: 20px;
    }
    .mobile-pd {
        padding-left: 20px;
    }
    .facility-mobile-pd {
        padding: 0 20px;
    }
    .leadership-bapulad-bg {
        display: none;
    }
    .leadership-arunlad-bg {
        display: none;
    }
    .leadeship-sharadlad-bg {
        display: none;
    }
    .notice-hgt {
        margin-bottom: 20px;
    }
    .tender-mobile-gap {
        display: block;
        margin: 10px 0;
    }
    .new-tender-card {
        margin-bottom: 20px;
    }
    .new-ngfc-bg {
        padding-left: 20px;
        padding-right: 20px;
    }
    .new-cmp-ovr {
        background-color: #FFF1EB;
        padding: 80px 10px;
    }
    .company-overview-card {
        border-bottom: 2px solid #cac9c9;
        border-right: 0;
        margin-bottom: 20px;
    }
    .main-news {
        padding-left: 0;
        padding-right: 0;
    }
    .other-news {
        padding-left: 0;
        padding-right: 0;
    }
    .other-news-lst {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 30px;
    }
    .newsroom-img {
        height: 200px;
    }
    .mrg-common {
        margin-top: 20px;
    }
    .crushing-sbtxt {
        margin-bottom: 20px;
    }
    .farmer-corner-wrp-new {
        padding: 90px 0;
        height: 650px;
    }
    .farmerc-title {
        font-size: 40px;
    }
    .gallery-pd {
        padding: 0;
    }
}