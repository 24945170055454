.distillery-banner {
    max-width: 1800px;
    background-image: url('/public/assets/imgs/department/aboutus-banner.png');
    background-repeat: no-repeat;
    background-position: center;
    padding: 150px 0px;
    margin: 0 auto;
    background-size: cover;
    position: relative;
    overflow: hidden;
}
.mob-distilery-list li {
    list-style: decimal;
}
.dis-bg {
    border-radius: 20px;
    background: #fff;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border: 1px solid #ddd;
    padding: 22px 24px;
    text-align: center;
    margin-bottom: 25px;
    transition: ease-in-out 0.5s;
}
.dis-bg:hover {
    border: 1px solid #c4c4c4;
    transform: translateY(-10px);
    transition: ease 0.5s;
}
.dis-card-flex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}
.dis-card-title {
    height: 48px;
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 18px!important;
    color: #333;
    margin-bottom: 0px;
}
.dis-card-txt {
    height: 120px;
    color: #777;
}

@media screen and (max-width: 768px) {
    .dis-bg {
        margin-bottom: 20px;
    }
}