.login-bg .slider-login {
    height: 900px;
}
.input-grp select {
    border: 0;
    background-color: transparent;
    line-height: initial;
    height: 60px;
    font-size: 16px;
}
.txtarea-grp textarea {
    border: 0;
    background-color: transparent;
    line-height: initial;
    font-size: 16px;
}
.txtarea-grp {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 24px;
    padding-left: 10px;
}
.txtarea-grp textarea:focus {
    box-shadow: none;
}
.form-select:focus {
    box-shadow: none;
}

@media screen and (max-width : 480px) {
    .login-bg .slider-login {
        height: 1200px!important;
    }
}