.agriculture-banner {
    max-width: 1800px;
    background-image: url('/public/assets/imgs/department/aboutus-banner.png');
    background-repeat: no-repeat;
    background-position: center;
    padding: 150px 0px;
    margin: 0 auto;
    background-size: cover;
    position: relative;
    overflow: hidden;
}
.agriculture-multimg {
    border-radius: 20px;
    overflow: hidden;
}
.agriculture-multimg img {
    border: none;
    height: 400px;
    object-fit: cover;
}

@media screen and (max-width : 768px) {
    .agriculture-multimg {
        margin-top: 20px;
    }
    .agriculture-multimg img {
        border: none;
        height: 350px;
        border: 1px solid #fb5220;
        object-fit: cover;
    }
}